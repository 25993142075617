
















































































import Vue, { PropType } from 'vue'
import { Order } from '../../../../shared/types/types'
import { LineItemExtended } from '../../types'
import Separator from '../shared/Separator.vue'
import TrackerCard from '../shared/TrackerCard.vue'
import ItemImage from '../shared/ItemImage.vue'

export default Vue.extend({
  name: 'FulfillmentCard',
  components: {
    Separator,
    TrackerCard,
    ItemImage,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
    isShippingRequired: {
      type: Boolean,
      required: true,
    },
    shouldSubmitTracker: {
      type: Boolean,
      required: true,
    },
    fulfillmentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fulfillmentStatusTypes: {
        fulfilled: 'Fulfilled',
        partial: 'Partially fulfilled',
        unfulfilled: 'Unfulfilled',
      },
      notifyCustomer: false,
    }
  },
  methods: {
    /**
     * It emits an event which is handled by parent component.
     *
     * @param item - contains details of item, updated quantity
     */
    emitItemQuantitySelect(item: LineItemExtended) {
      this.$emit('change-selected-quantity', item)
    },
  },
})
