import { render, staticRenderFns } from "./OrderFulfillment.vue?vue&type=template&id=5e28f182&"
import script from "./OrderFulfillment.vue?vue&type=script&lang=ts&"
export * from "./OrderFulfillment.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports