






















import Vue from 'vue'
import pluralize from 'pluralize'
import Separator from '../shared/Separator.vue'

export default Vue.extend({
  name: 'FulfillmentSummaryCard',
  components: {
    Separator,
  },
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    selectedItems: {
      type: Number,
      required: true,
    },
    isFulfilling: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    disableFulfillment(): boolean {
      return this.selectedItems === 0
    },
    totalItemsText(): string {
      return pluralize('Item', this.totalItems, true)
    },
  },
})
