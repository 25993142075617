















































import Vue from 'vue'
import NavigateBack from '../../components/shared/NavigateBack.vue'
import FulfillmentCard from '../../components/orders/FulfillmentCard.vue'
import FulfillmentSummaryCard from '../../components/orders/FulfillmentSummaryCard.vue'
import AddressCard from '../../components/shared/AddressCard.vue'
import Separator from '../../components/shared/Separator.vue'
import { getOrder } from '../../lib/order'
import { fulfillLineItems } from '../../lib/order'
import {
  groupedFulfillmentLineItems,
  generateKSUID,
  filterRefundedLineItems,
} from '../../lib/utils'
import Loader from '../../components/shared/Loader.vue'
import {
  Maybe,
  Order,
  FulfillmentStatusEnum,
  VariantTypeEnum,
  FulfillmentItemInput,
  AddressInput,
  LineItem,
  Refund,
} from '../../../../shared/types/types'
import {
  LineItemExtended,
  FulfillmentCard as IFulfillmentCard,
} from '../../types'

export default Vue.extend({
  name: 'OrderFulfillment',
  components: {
    NavigateBack,
    FulfillmentCard,
    AddressCard,
    FulfillmentSummaryCard,
    Separator,
    Loader,
  },
  data() {
    return {
      order: {} as Maybe<Order>,
      shipmentType: 'Free Shipping ($0.00)',
      totalQuantitySelected: 0 as number,
      lineItems: [] as LineItemExtended[],
      receivedOrder: false,
      shouldSubmitTracker: false,
      isFulfilling: false,
      fulfillmentId: generateKSUID(),
    }
  },
  computed: {
    orderId(): string {
      return this.$route.params.id
    },
    totalItems(): number {
      return this.lineItems.reduce(
        (acc: number, item: LineItemExtended) => acc + (item?.quantity || 0),
        0
      )
    },
    /**
     * Responsible to create a tailored object for FulfillmentCard with filtered lineItems
     */
    fulfillmentCardData(): IFulfillmentCard {
      return {
        lineItems: this.lineItems,
        orderId: this.orderId,
        customerName: `${this.order?.customer?.firstName || ''} ${
          this.order?.customer?.lastName || ''
        }`,
        fulfillmentStatus:
          this.order?.fulfillmentStatus || FulfillmentStatusEnum.Unfulfilled,
      }
    },
    isShippingRequired(): boolean {
      return this.$route.params.variant === VariantTypeEnum.Physical
    },
  },
  async created() {
    try {
      this.order = await getOrder(this.orderId)
      const filterRefundedLineItemsResponse = filterRefundedLineItems(
        this.order?.lineItems as LineItem[],
        ((this.order as Order).refunds as Refund[]) || []
      )
      ;(this.order as Order).lineItems =
        filterRefundedLineItemsResponse?.lineItems
      this.setLineItems()
      this.receivedOrder = true
    } catch (error) {
      this.$buefy.toast.open({
        message: 'Order is not Available',
        position: 'is-bottom',
        type: 'is-danger',
      })
    }
  },
  methods: {
    /**
     * Sets selectedQuantity property in order object to track quantity selected by user. (It might change during integration with backend)
     */
    setSelectedQuantityInOrder() {
      this.lineItems = this.lineItems.map((item) => {
        item = { ...item, selectedQuantity: item.quantity || 0 }
        this.totalQuantitySelected += item.quantity || 0
        return item
      })
    },
    goToOrderDetails() {
      this.$router.push(`/orders/${this.orderId}`)
    },
    async fulfillOrder() {
      this.isFulfilling = true
      let fulfillmentItemInput = [] as FulfillmentItemInput[]
      this.lineItems.map((item) => {
        if (item.selectedQuantity) {
          fulfillmentItemInput.push({
            id: item.lineItemId || '',
            quantity: item?.selectedQuantity || 0,
          })
        }
      })
      const shippingAddress = {
        ...(this.order?.customer?.shippingAddress || {}),
      }
      delete shippingAddress['__typename']
      const fulfillmentArgs = {
        fulfillment: {
          id: this.fulfillmentId,
          lineItems: fulfillmentItemInput,
          shippingAddress: shippingAddress as AddressInput,
        },
      }
      try {
        await fulfillLineItems(this.orderId, fulfillmentArgs)
        this.shouldSubmitTracker =
          this.$route.params.variant === VariantTypeEnum.Physical
        if (this.$route.params.variant === VariantTypeEnum.Virtual) {
          this.goToOrderDetails()
        }
        this.$buefy.toast.open({
          message: 'Line Items have been Fulfilled!',
          type: 'is-success',
          position: 'is-bottom',
        })
      } catch (error) {
        this.isFulfilling = false
        this.$buefy.toast.open({
          message: 'Failed to fulfill the line items. Please try again later.',
          type: 'is-danger',
          position: 'is-bottom',
        })
      }
    },
    /**
     * Changes selected quantity in order object, updates totalQuantitySelected property
     *
     * @param item data transfered by FulfillmentCard when user changes quantity of lineItems.
     */
    changeSelectedQuantity(item: LineItemExtended) {
      let selected = 0
      this.lineItems = this.lineItems.map((lineItem) => {
        selected += +(lineItem.selectedQuantity || 0)
        if (lineItem.lineItemId === item.lineItemId) {
          lineItem.selectedQuantity = +(item.selectedQuantity || 0)
        }
        return lineItem
      })
      this.totalQuantitySelected = selected
    },
    /**
     * sets lineItems data variable with filtered lineItems from order data
     */
    setLineItems() {
      const unfulfilledLineItems = groupedFulfillmentLineItems(
        FulfillmentStatusEnum.Unfulfilled,
        this.$route.params.variant as VariantTypeEnum,
        this.order?.lineItems || [],
        this.order?.fulfillments
      )
      this.lineItems = unfulfilledLineItems.length
        ? unfulfilledLineItems[0].lineItems
        : []
      this.setSelectedQuantityInOrder()
    },
  },
})
